import _taggedTemplateLiteral from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import styled from 'styled-components';
import UIButton from '@/components/ui/button';
import UICircle from '@/components/ui/circle';
import UIPaper from '@/components/ui/paper';
import UIProgressRing from '@/components/ui/progress/ring';
import { media, spacingVer } from '@/components/utils/style';
var StyledPageProfilebarLeaderboardChild = styled.div.withConfig({
  displayName: "StyledPageProfilebarLeaderboardChild",
  componentId: "sc-1ysussc-0"
})(["display:flex;width:33.3333%;flex-flow:column;", ""], spacingVer(10));
export var StyledPageProfilebarLeaderboardAvatar = styled(StyledPageProfilebarLeaderboardChild).withConfig({
  displayName: "StyledPageProfilebarLeaderboardAvatar",
  componentId: "sc-1ysussc-1"
})(["text-decoration:none;&:first-child:last-child{width:100%;}"]);
export var StyledPageProfilebarLeaderboardGroup = styled(StyledPageProfilebarLeaderboardChild).withConfig({
  displayName: "StyledPageProfilebarLeaderboardGroup",
  componentId: "sc-1ysussc-2"
})([""]);
export var StyledPageProfilebarLeaderboardTotalScore = styled(StyledPageProfilebarLeaderboardChild).withConfig({
  displayName: "StyledPageProfilebarLeaderboardTotalScore",
  componentId: "sc-1ysussc-3"
})(["", "{letter-spacing:-1.25px;}"], UICircle);
export var StyledPageProfilebarLeaderboard = styled.div.withConfig({
  displayName: "StyledPageProfilebarLeaderboard",
  componentId: "sc-1ysussc-4"
})(["display:flex;justify-content:center;text-decoration:none;"]);
export var StyledPageProfilebarSection = styled(UIPaper).withConfig({
  displayName: "StyledPageProfilebarSection",
  componentId: "sc-1ysussc-5"
})(["display:flex;padding:16px 12px;border-radius:5px;text-decoration:none;justify-content:center;flex-flow:column;cursor:pointer;> *{margin-top:0;margin-bottom:3px;&:first-child{margin-bottom:-6px;}&:last-child{margin-bottom:0;}}"]);
var StyledPageProfilebar = styled(UIPaper).attrs(function (props) {
  return _objectSpread({
    as: 'aside'
  }, props);
}).withConfig({
  displayName: "StyledPageProfilebar",
  componentId: "sc-1ysussc-6"
})(["display:flex;border-radius:10px;margin-bottom:auto;padding:12px;width:100%;flex-flow:column wrap;align-self:flex-start;", " ", " + ", "{margin:20px auto 0;}", ",", "{margin-right:auto;margin-left:auto;}", "{position:relative;margin-top:12px;}"], media.profilebar(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    width: 330px;\n  "]))), spacingVer(15), UIButton, UICircle, UIProgressRing, UICircle);
export default StyledPageProfilebar;