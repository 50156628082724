import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import { themeColorPrimary } from '@/components/utils/style';
var UICircle = styled.div.withConfig({
  displayName: "UICircle",
  componentId: "ww1q5s-0"
})(["display:flex;border-radius:50%;height:", "px;width:", "px;align-items:center;justify-content:center;", " ", ""], prop('size', 52), prop('size', 52), ifProp('outline', css(["border-style:solid;border-width:1px;background-color:", ";"], theme('color.white'))), switchProp(prop('variant', 'default'), {
  "default": css(["", "-color:", ";"], ifProp('outline', 'border', 'background'), theme('color.mystic')),
  primary: css(["", "-color:", ";"], ifProp('outline', 'border', 'background'), themeColorPrimary),
  raven: css(["", "-color:", ";"], ifProp('outline', 'border', 'background'), theme('color.raven')),
  white: css(["", "-color:", ";"], ifProp('outline', 'border', 'background'), theme('color.white'))
}));
export default UICircle;