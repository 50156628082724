import _taggedTemplateLiteral from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import styled from 'styled-components';
import { theme } from 'styled-tools';
import { media, spacingHor } from '@/components/utils/style';
var UIPage = styled.div.withConfig({
  displayName: "UIPage",
  componentId: "posxtw-0"
})(["display:flex;flex-flow:column nowrap;"]);
export var UIPageBody = styled.div.withConfig({
  displayName: "UIPageBody",
  componentId: "posxtw-1"
})(["display:flex;margin:0 auto 40px;padding:0 15px;max-width:", ";width:100%;flex-flow:row;", " ", " ", ""], theme('page.body.maxWidth'), spacingHor(15), media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    margin: 0 auto 40px;\n  "]))), media.desktop(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    ", "\n  "])), spacingHor(30)));
export var UIPageBodyInsights = styled(UIPageBody).withConfig({
  displayName: "UIPageBodyInsights",
  componentId: "posxtw-2"
})(["", ""], media.mobile(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 0;\n    border-radius: 0;\n  "]))));
export var UIPageMain = styled.div.withConfig({
  displayName: "UIPageMain",
  componentId: "posxtw-3"
})(["position:relative;width:inherit;flex-grow:1;", ""], media.desktop(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: calc(100% - ", " - 2 * ", ");\n  "])), theme('page.sidebar.width'), theme('paper.padding')));
export default UIPage;