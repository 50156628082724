import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, prop, switchProp, theme } from 'styled-tools';
var UIPaper = styled.div.withConfig({
  displayName: "UIPaper",
  componentId: "sc-1rft94s-0"
})(["", ";padding:", ";border-radius:", ";", ";background-color:", ";", ""], ifProp('width', css(["width:", "px;"], prop('width'))), ifProp('noPadding', '0', '2rem'), theme('paper.border.radius'), ifNotProp({
  boxShadow: 'none'
}, css(["box-shadow:", ";"], theme('paper.box.shadow'))), theme('paper.background.color'), switchProp(prop('variant', 'default'), {
  danger: css(["border:", " ", " ", ";"], theme('paper.border.width'), theme('paper.border.style'), theme('color.danger')),
  mariner: css(["border:", " ", " ", ";"], theme('paper.border.width'), theme('paper.border.style'), theme('color.mariner')),
  darkBlue: css(["border:", " ", " ", ";"], theme('paper.border.width'), theme('paper.border.style'), theme('color.darkBlue')),
  surf: css(["border:", " ", " rgba(182,195,220,0.5);"], theme('paper.border.width'), theme('paper.border.style'))
}));
export default UIPaper;