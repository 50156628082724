import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _taggedTemplateLiteral from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import styled, { createGlobalStyle, css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { UIPageMain } from '@/components/ui/page';
import UIPageProfilebar from '@/components/ui/page/profilebar/styled';
import { media, spacingHor } from '@/components/utils/style';
var StyledPageTraining = styled(UIPageMain).withConfig({
  displayName: "StyledPageTraining",
  componentId: "sc-194xybi-0"
})(["display:flex;flex-flow:column;max-width:100%;padding:0;", " ", " ", " ", ""], ifProp('isWebView', css(["margin-top:", ";"], theme('default.padding'))), media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    padding-top: ", ";\n    padding-bottom: ", ";\n  "])), theme('default.padding'), theme('default.padding')), media.profilebar(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    flex-flow: row;\n\n    ", "\n  "])), spacingHor(20)), media.desktop(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    ", "\n  "])), spacingHor(30)));
export var StyledPageTrainingMain = styled.main.attrs(function (props) {
  return _objectSpread({
    id: 'start-of-content'
  }, props);
}).withConfig({
  displayName: "StyledPageTrainingMain",
  componentId: "sc-194xybi-1"
})(["order:1;", " ", ""], media.profilebar(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    flex-grow: 1;\n    order: 0;\n  "]))), media.desktop(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    width: calc(100% - ", " - 2 * ", " - ", ");\n  "])), theme('page.sidebar.profile.width'), theme('paper.padding'), theme('default.padding.desktop')));
export var StyledHideProfilebar = createGlobalStyle(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", " {\n    display: none;\n  }\n\n  ", "\n"])), UIPageProfilebar, media.tablet(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    ", " {\n      display: block;\n    }\n  "])), UIPageProfilebar));
export default StyledPageTraining;