import { enabledLanguages } from '@/lib/languages-utils';
export function capitalize(string) {
  return "".concat(string.substring(0, 1).toUpperCase()).concat(string.substring(1));
}
export function isLanguage(value) {
  return Boolean(value && enabledLanguages()[value]);
}
export function notEmptyOrNull(value) {
  return value.length ? value : null;
}
export function template(string, values) {
  var regexp = /%{([^{]+)}/g;
  return string.replace(regexp, function (ignore, key) {
    return key in values ? values[key] : '';
  });
}
export function isString(value) {
  return typeof value === 'string';
}