import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { multiply } from 'ramda';
import styled, { css, keyframes } from 'styled-components';
import { prop, switchProp, theme } from 'styled-tools';
import { themeColorPrimary } from '@/components/utils/style';
var multiply01 = multiply(0.1);
var multiply015 = multiply(0.15);
var multiply07 = multiply(0.7);
var multiply08 = multiply(0.8);
var uiSpinner = keyframes(["0%{transform:rotate(0deg);}100%{transform:rotate(360deg);}"]);
var UISpinner = styled.div.attrs(function (props) {
  return _objectSpread(_objectSpread({}, props), {}, {
    'data-testid': 'ui-spinner'
  });
}).withConfig({
  displayName: "UISpinner",
  componentId: "que6l9-0"
})(["display:inline-block;position:relative;&:after{box-sizing:border-box;position:absolute;display:block;border-style:solid;border-radius:50%;content:'';animation:", " 1s cubic-bezier(0.5,0,0.5,1) infinite;}", " ", ""], uiSpinner, switchProp(prop('size', 'medium'), {
  semiMedium: css(["width:", "px;height:", "px;&:after{margin:", "px;border-width:", "px;width:", "px;height:", "px;}"], theme('spinner.size.semiMedium'), theme('spinner.size.semiMedium'), function (props) {
    return multiply015(props.theme['spinner.size.semiMedium']);
  }, function (props) {
    return multiply015(props.theme['spinner.size.semiMedium']);
  }, function (props) {
    return multiply07(props.theme['spinner.size.semiMedium']);
  }, function (props) {
    return multiply07(props.theme['spinner.size.semiMedium']);
  }),
  medium: css(["width:", "px;height:", "px;&:after{margin:", "px;border-width:", "px;width:", "px;height:", "px;}"], theme('spinner.size'), theme('spinner.size'), function (props) {
    return multiply01(props.theme['spinner.size']);
  }, function (props) {
    return multiply01(props.theme['spinner.size']);
  }, function (props) {
    return multiply08(props.theme['spinner.size']);
  }, function (props) {
    return multiply08(props.theme['spinner.size']);
  }),
  semiLarge: css(["width:", "px;height:", "px;&:after{margin:", "px;border-width:", "px;width:", "px;height:", "px;}"], theme('spinner.size.semiLarge'), theme('spinner.size.semiLarge'), function (props) {
    return multiply01(props.theme['spinner.size.semiLarge']);
  }, function (props) {
    return multiply01(props.theme['spinner.size.semiLarge']);
  }, function (props) {
    return multiply08(props.theme['spinner.size.semiLarge']);
  }, function (props) {
    return multiply08(props.theme['spinner.size.semiLarge']);
  }),
  large: css(["width:", "px;height:", "px;&:after{margin:", "px;border-width:", "px;width:", "px;height:", "px;}"], theme('spinner.size.large'), theme('spinner.size.large'), function (props) {
    return multiply01(props.theme['spinner.size.large']);
  }, function (props) {
    return multiply01(props.theme['spinner.size.large']);
  }, function (props) {
    return multiply08(props.theme['spinner.size.large']);
  }, function (props) {
    return multiply08(props.theme['spinner.size.large']);
  })
}), switchProp(prop('variant', 'default'), {
  "default": css(["&:after{border-color:", ";border-top-color:transparent;}"], theme('spinner.border.color')),
  darkBlue: css(["&:after{border-color:", ";border-top-color:transparent;}"], theme('color.darkBlue')),
  primary: css(["&:after{border-color:", ";border-top-color:transparent;}"], themeColorPrimary),
  white: css(["&:after{border-color:", ";border-top-color:transparent;}"], theme('color.white')),
  dark: css(["&:after{border-color:", ";border-top-color:transparent;}"], theme('color.black'))
}));
export default UISpinner;