import ResourcesOneappTrainingProfile from '@/lib/resources/oneapp/training/profile';
import { catchError } from '@/redux/actions/error';
export var PROFILE_FETCH_ERROR = 'PROFILE_FETCH_ERROR';
export var PROFILE_FETCH_START = 'PROFILE_FETCH_START';
export var PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';

var fetchProfileError = function fetchProfileError() {
  return {
    type: PROFILE_FETCH_ERROR
  };
};

var fetchProfileStart = function fetchProfileStart() {
  return {
    type: PROFILE_FETCH_START
  };
};

var fetchProfileSuccess = function fetchProfileSuccess(payload) {
  return {
    type: PROFILE_FETCH_SUCCESS,
    payload: payload
  };
};

export function fetchProfile() {
  return function (dispatch) {
    dispatch(fetchProfileStart());
    return ResourcesOneappTrainingProfile.get().then(function (_ref) {
      var profile = _ref.data;
      dispatch(fetchProfileSuccess({
        profile: profile
      }));
    })["catch"](function (response) {
      dispatch(fetchProfileError());
      dispatch(catchError(response));
    });
  };
}